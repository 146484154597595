<template>
  <div class="table-responsive text-nowrap">
    <a-table :columns="columns" :data-source="data" :rowkey="_id" :loading="tableLoading">
      <template #filterDropdown="{
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
          column,
        }"
      >
        <div class="custom-filter-dropdown">
          <a-input
            :placeholder="`Search ${column.dataIndex}`"
            :value="selectedKeys[0]"
            style="width: 188px; margin-bottom: 8px; display: block"
            @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
            @pressEnter="() => handleSearch(selectedKeys, confirm)"
          />
          <a-button
            type="primary"
            size="small"
            style="width: 90px; margin-right: 8px"
            @click="() => handleSearch(selectedKeys, confirm)"
            >Search</a-button
          >
          <a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)"
            >Reset</a-button
          >
        </div>
      </template>
      <template v-for="col in ['ar', 'fr', 'en']"  :key="col" #[col]="{ text, record }">
        <a-input
          v-if="editableData[record._id]"
          v-model:value="editableData[record._id]['title'][col]"
          style="margin: -5px 0"
        />
        <template v-else>
          {{ text }}
        </template>
      </template>
      <template #filterIcon="{ text: filtered }">
        <a-icon type="search" :style="{ color: filtered ? '#108ee9' : undefined }" />
      </template>   
      <template #action="{ record }">
        <div class="editable-row-operations">  
          <span v-if="editableData[record._id]">
            <a class="btn btn-sm btn-success mr-2" @click="save(record._id)">Sauvegarder</a>
            <a-popconfirm title="Êtes-vous sûr d'annuler?" @confirm="cancel(record._id)">
              <a class="btn btn-sm btn-danger mr-2">Annuler</a>
            </a-popconfirm>
          </span>                
        <span v-else>
          <a class="btn btn-sm btn-light mr-2" @click="edit(record._id)" >
            <i class="fe fe-edit mr-2" />
            Modifier
          </a>
          <a class="btn btn-sm btn-light" @click="suppPubCat(record)">
            <small>
              <i class="fe fe-trash mr-2" />
            </small>
            {{record.status=='active'?'Désactiver':"Activer"}}
          </a>
        </span>
        </div>
      </template>     
      <template #status="{ text }">
        <span
          :class="[
            text === 'inactive'
              ? 'font-size-12 badge badge-primary'
              : 'font-size-12 badge badge-success',
          ]"
          >{{ text === 'inactive'?'Inactive' :"Active"}}</span
        >
      </template>      
    </a-table>
  </div>
</template>

<script>
/* eslint-disable */
import ApiClient from "@/services/axios"
import { message } from 'ant-design-vue';
import { cloneDeep } from 'lodash-es';
import { reactive, toRefs, defineComponent } from 'vue';

const columns =
[        
  {
    title: 'Titre Fr',
    dataIndex: 'title.fr',
    slots: {
        filterDropdown: 'filterDropdown',
        filterIcon: 'filterIcon',
        customRender: 'fr',
    },
    sorter: (a, b) => a.title.fr.length - b.title.fr.length,
    onFilter: (value, record) => record.title.fr.toLowerCase().includes(value.toLowerCase()),
  },   
  {
    title: 'Titre Ar',
    dataIndex: 'title.ar',
    slots: {
        filterDropdown: 'filterDropdown',
        filterIcon: 'filterIcon',
        customRender: 'ar',
    },
    sorter: (a, b) => a.title.ar.length - b.title.ar.length,
    onFilter: (value, record) => record.title.ar.toLowerCase().includes(value.toLowerCase()),
  },
  {
    title: 'Titre Ang',
    dataIndex: 'title.en',
    slots: {
        filterDropdown: 'filterDropdown',
        filterIcon: 'filterIcon',
        customRender: 'en',
    },
    sorter: (a, b) => a.title.en.length- b.title.en.length,
    onFilter: (value, record) => record.title.en.toLowerCase().includes(value.toLowerCase()),
  },                     
  {
    title: 'Status',
    dataIndex: 'status',
    sorter: (a, b) => a.status.length - b.status.length,
    slots: { customRender: 'status' },
  },
  {
    title: 'Action',
    slots: { customRender: 'action' },
  },
]
export default defineComponent({
  //["data","tableLoading"]
  props:{
    data:Array,
    tableLoading:Boolean
  },
  emits:["changeData"],
setup(props, {emit}) {
  const {data}= toRefs(props)
  const dataSource =data
    const editableData = reactive({});

    const edit = key => {
      editableData[key] = cloneDeep(dataSource.value.filter(item => key === item._id)[0]);
    };

    const save = key => {
      ApiClient.patch('/actualites_cetegories/'+key,{data:editableData[key]})
      .then(()=>{
        Object.assign(dataSource.value.filter(item => key === item._id)[0], editableData[key]);
        emit("changeData",dataSource.value)
        message.success("Titre de catégorie est changé")
      })
      .catch(()=>message.error("Impossible de changer de catégorie"))
      .finally(()=>delete editableData[key])
        
    };

    const cancel = key => {
      delete editableData[key];
    };
    const handleSearch = (selectedKeys, confirm)=>{
      confirm()
      searchText.value = selectedKeys[0]
    }
    const handleReset=()=>{
      clearFilters(clearFilters)
      searchText.value = ''
    }
    return {
      dataSource,
      columns,
      editingKey: '',
      editableData,
      edit,
      save,
      cancel,
      handleSearch,
      handleReset
    };
  },
  data(){
    return {
      searchText:'',
      searchInput:null,
    }
  },
  methods: {
    suppPubCat(record){
      const updateData = {status:record.status=='active'?"inactive":'active'}
      ApiClient.patch('/actualites_cetegories/'+record._id,{
        data:updateData
      }).then(()=>{
        this.$emit("changeData",[...this.data.map((elem)=>elem._id == record._id?{...elem,status:record.status=='active'?"inactive":'active'}:elem)])
        message.success(`La publication est ${record.status=='active'?"désactivé":'activé'}`)
      })
      .catch(()=>{
        message.warning("Impossible d'activer/désactiver la catégorie de cette publication")
      })     
      }
  },
})
</script>

<style scoped>
.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
